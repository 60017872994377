<template>
  <block
    component-name="block-carousel-rich"
    ref="tpl_root"
    :px="false"
    class="overflow-hidden"
    body-class="overflow-hidden pb-5xl"
  >
    <template v-slot:default="blockData">
      <div
        ref="tpl_wrapper"
        class="overflow-hidden relative top-0 left-0 w-full"
        v-surfer="{
          setup: {
            destroyOn: 'visible',
          },
          observers: {
            init: true,
            visible: { event: true },
            offscreenTop: { event: true },
            offscreenBottom: { event: true },
          },
        }"
        @surfer-visible="handleSurfer('visible', false)"
        @surfer-offscreen-top="handleSurfer('offscreen-top', true)"
        @surfer-offscreen-bottom="handleSurfer(null, true)"
      >
        <swiper-container
          init="false"
          class="overflow-hidden w-full h-full"
          events-prefix="swiper-"
        >
          <swiper-slide
            v-for="(item, index) in $attrs.data.body.items"
            class="overflow-hidden relative top-0 left-0"
          >
            <slide-carousel-rich
              :index="index"
              :asset="{
                ...item.asset,
                loading: 'auto',
              }"
              :title="item.title"
              :details="item.details"
              class="w-full"
              :class="{
                'translate-y-[100vh]': index < 4,
                '!translate-y-0':
                  index < 4 &&
                  (surferState === 'visible' ||
                    surferState === 'offscreen-top'),
                'duration-[850ms] ease-in-out':
                  index < 4 && surferState === 'visible',
                'delay-100':
                  index === 1 &&
                  (surferState === 'visible' ||
                    surferState === 'offscreen-top'),
                'delay-200':
                  index === 2 &&
                  (surferState === 'visible' ||
                    surferState === 'offscreen-top'),
                'delay-300':
                  !$attrs.data.body.text &&
                  index === 3 &&
                  (surferState === 'visible' ||
                    surferState === 'offscreen-top'),
              }"
            />
          </swiper-slide>
        </swiper-container>
      </div>

      <wrapper-reveal
        class="absolute right-0 bottom-0 left-0 w-full h-[80%] phablet:h-[75%] tablet:h-[70%]"
        reveal="slide-from-bottom reveal-strong"
      >
        <div
          class="h-full w-full bg-dynamic transition-colors duration-700 ease-in-out"
          :style="`--color-fill-dynamic: ${accentColor || '#000'};`"
        ></div>
      </wrapper-reveal>

      <footer
        v-if="$attrs.data.body.items.length > 1"
        data-skin="black"
        class="hidden laptop:flex absolute inset-0 items-end justify-end w-full h-full pointer-events-none z-2 gap-lg pr-edge pb-3xl"
      >
        <wrapper-reveal class="pointer-events-auto" reveal="fade-from-right">
          <btn-arrow
            :disabled="currentIndex === 0"
            direction="prev"
            @click="handleCarouselProgress('prev')"
          />
        </wrapper-reveal>

        <wrapper-reveal class="pointer-events-auto" reveal="fade-from-left">
          <btn-arrow
            :disabled="currentIndex === $attrs.data.body.items.length - 1"
            direction="next"
            @click="handleCarouselProgress('next')"
          />
        </wrapper-reveal>
      </footer>
    </template>
  </block>
</template>

<script setup>
const attrs = useAttrs();
const tpl_root = templateRef("tpl_root");
const tpl_wrapper = templateRef("tpl_wrapper");
const { height: wrapperH } = useElementSize(tpl_wrapper);
const currentIndex = ref(0);
const surferState = ref(null);
const { setLuminance } = useColors();

const accentColor = computed(() => {
  return setLuminance(
    attrs.data.body.items[currentIndex.value].asset.dominant_color
  );
});

let swiperEl;

function handleCarouselProgress(nextPrev) {
  switch (nextPrev) {
    case "prev":
      swiperEl?.swiper.slidePrev();
      break;

    case "next":
      swiperEl?.swiper.slideNext();
      break;
  }
}

function handleSurfer(val, resetSlides = false) {
  surferState.value = val;
  if (resetSlides) swiperEl?.swiper?.slideTo(0, 0, false);
}

useSafeMountedEl(tpl_root, () => {
  swiperEl = tpl_root.value.$el.querySelector("swiper-container");
  Object.assign(swiperEl, {
    grabCursor: true,
    parallax: true,
    speed: 650,
    slidesPerGroup: 1,
    slidesPerView: 1.2,
    slidesOffsetBefore: 24,
    spaceBetween: 24,
    slidesOffsetAfter: 24,
    breakpoints: {
      600: {
        slidesPerView: 1.4,
        slidesOffsetBefore: 80,
        spaceBetween: 40,
        slidesOffsetAfter: 80,
      },
      1020: {
        slidesPerView: 1.7,
        slidesOffsetBefore: 120,
        spaceBetween: 80,
        slidesOffsetAfter: 120,
      },
      1272: {
        slidesPerView: 1.7,
        slidesOffsetBefore: 200,
        spaceBetween: 120,
        slidesOffsetAfter: 200,
      },
      1512: {
        slidesPerView: 2.1,
        slidesOffsetBefore: 200,
        spaceBetween: 120,
        slidesOffsetAfter: 200,
      },
      1920: {
        slidesPerView: 2.7,
        slidesOffsetBefore: 200,
        spaceBetween: 120,
        slidesOffsetAfter: 200,
      },
    },
    on: {
      init() {
        ScrollTrigger.refresh(true);
      },
      slideChange(swiper) {
        currentIndex.value = swiper.realIndex;
      },
    },
  });
  swiperEl.initialize();
});

onUnmounted(() => {
  swiperEl?.swiper?.destroy();
  swiperEl = null;
});
</script>
