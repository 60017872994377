<template>
  <div
    component-name="slide-carousel-rich"
    data-skin="black"
    class="relative top-0 left-0 flex flex-col overflow-hidden gap-2xl tablet:gap-3xl"
  >
    <component
      :is="details?.button ? 'btn' : 'span'"
      :title="details?.button ? asset.alt : undefined"
      :action="details?.button ? details.button.action : undefined"
      :class="{
        'overflow-hidden w-full': details?.button,
        contents: !details?.button,
      }"
    >
      <media
        class="w-full"
        :class="{
          'transition-transform duration-1000 group-hover/btn:scale-105':
            details?.button,
          '!scale-105': forceMediaHover,
        }"
        tag="div"
        v-bind="asset"
        :sizes="{
          base: 500,
          phablet: 750,
          laptop: 900,
          desktop: 950,
        }"
      />
    </component>

    <footer
      class="flex flex-col tablet:flex-row gap-2xl tablet:items-start tablet:gap-3xl text-skin-base"
    >
      <div
        class="flex flex-col w-full tablet:flex-row tablet:items-start tablet:w-7/12 gap-xs phablet:gap-2xl"
      >
        <wrapper-reveal reveal="fade-from-down">
          <div
            class="text-heading-5 leading-snug tabular-nums"
            v-html="`${index + 1 < 10 ? '0' : ''}${index + 1}.`"
          ></div>
        </wrapper-reveal>
        <component
          :is="details.button ? 'btn' : 'span'"
          :title="details.button ? title : undefined"
          :action="details.button ? details.button.action : undefined"
          :class="{
            contents: !details.button,
          }"
          @mouseenter="titleHasHover = $device.isDesktop"
          @mouseleave="titleHasHover = false"
        >
          <wrapper-split-text reveal="words">
            <div class="text-heading-5 leading-snug" v-html="title"></div>
          </wrapper-split-text>
        </component>
      </div>

      <div
        v-if="details && (details?.title || details?.text || details?.button)"
        class="flex flex-col items-start w-full gap-md tablet:w-4/12"
      >
        <div class="flex flex-col w-full gap-2xs">
          <wrapper-reveal v-if="details.title" reveal="fade-from-right">
            <div class="text-overline" v-html="details.title"></div>
          </wrapper-reveal>

          <wrapper-reveal v-if="details.text" reveal="fade-from-bottom">
            <div class="text-body-small" v-html="details.text"></div>
          </wrapper-reveal>
        </div>

        <btn-nav
          v-if="details.button"
          size="small"
          :title="details.button.title"
          :action="details.button.action"
        />
      </div>
    </footer>
  </div>
</template>

<script setup>
const props = defineProps({
  asset: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  details: {
    type: Object,
    required: false,
  },
});

const titleHasHover = ref(false);
const forceMediaHover = computed(() => {
  return titleHasHover.value && props.details?.button;
});
</script>
